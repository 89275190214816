<template>

    <div class="az-signin-wrapper">
        <!-- az-card-signin -->
        <div class="az-card-signin">
            <img alt="" class="az-card-signin-logo" src="../../assets/logo-orange-full.png">
            <div class="az-signin-header">
                <h3>Welcome back!</h3>
                <h5 style="margin-bottom:25px;">Please sign in to continue</h5>
                <div>
                    <div class="form-group">
                        <label for="email">Email address</label>
                        <input :class="{'is-invalid': submitted && errors.has('email')}" class="form-control rounded" data-vv-as="Email" id="email" name="email" placeholder="yourname@yourmail.com"
                               type="email" v-model="credentials.email" v-validate="'required|email'">
                        <div class="invalid-feedback" v-if="submitted && errors.has('email')">{{ errors.first('email') }}</div>
                    </div><!-- form-group -->
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input :class="{'is-invalid': submitted && errors.has('password')}" class="form-control rounded" data-vv-as="Password" id="password" name="password"
                               placeholder="Enter your password" type="password" v-model="credentials.password" v-validate="'required'">
                        <div class="invalid-feedback" v-if="submitted && errors.has('password')">{{ errors.first('password') }}</div>
                    </div><!-- form-group -->
                    <button @click="login" class="btn btn-az-secondary btn-block rounded">Sign In</button>
                </div>
            </div><!-- az-signin-header -->
            <div class="az-signin-footer">
                <p><router-link @click="forgotPassword" :to="{name: 'forgotpassword'}">Forgot password?</router-link></p>
                <p>Don't have an account?
                    <router-link :to="{name: 'signup'}">Create an Account</router-link>
                </p>
            </div><!-- az-signin-footer -->
        </div>
    </div><!-- az-signin-wrapper -->

</template>

<script>
    import myaxios from "axios";

    export default {
        name: "Login",
        data() {
            return {
                submitted: false,
                loading: false,
                credentials: {
                    email: '',
                    password: ''
                },
                loggingIn: false,
                error: ''
            };
        },
        mounted() {
            this.init();
            this.$parent.release();
        },
        methods: {
            init() {
            },
            login() {
                this.submitted = true;
                this.$validator.validate()
                        .then(valid => {
                            if (valid) {
                                self.loading = true;
                                this.$accounts.post('/accounts/users/login', this.credentials)
                                        .then(reply => this.loginSuccessful(reply))
                                        .catch(err => this.loginFailed(err));
                            }
                        });
            },
            loginSuccessful(reply) {
                this.loading = false;
                let token = reply.data.token;
                if (!token || reply.data.mobile_only) {
                    this.loginFailed();
                    return;
                }
                if (reply.data.image) {
                    localStorage.setItem("user_image", reply.data.image.replace('https://profile-console.s3.ap-south-1.amazonaws.com/', 'https://d10yw3sjtpttkq.cloudfront.net/'));
                } else {
                    localStorage.removeItem("user_image");
                }
                if (reply.data.org_image) {
                    localStorage.setItem("org_image", reply.data.org_image.replace('https://profile-console.s3.ap-south-1.amazonaws.com/', 'https://d10yw3sjtpttkq.cloudfront.net/'));
                } else {
                    localStorage.removeItem("org_image");
                }

                this.$store.commit("LOGIN", reply.data);
                this.$router.replace(this.$route.query.redirect || '/identities');
                this.$toastr.s("Successfully logged in!", 'Success');
            },
            loginFailed(err) {
                this.loading = false;
                let data = err ? err.response : null;
                if (data && data.status === 404) {
                    this.$toastr.e("Profile not found!", "Error");
                }
                if (data && data.status === 403) {
                    this.$toastr.e(data.data.error, "Error");
                } else {
                    this.$toastr.e("Authentication Error", "Error");
                }

                this.error = 'Login failed!';
                this.$store.dispatch('logout');
                delete localStorage.token;
            },
            async forgotPassword(){
                await myaxios.patch(process.env.VUE_APP_ACCOUNTS_URL + `/accounts/users/forgot_password`, {
                    email: this.credentials.password
                }, {
                    headers: {
                        'Content-Type': 'multipart/json',
                        'Authorization': 'BWS ' + this.token
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>
